<template>
    <div id="content" class="blank">
        <div class="content full hide-scrollbar"></div>
    </div>
</template>

<script>
export default {
    name: 'EmptyPage',
    data() {
        return {}
    },
    computed: {},
    methods: {},
    created() {
        if (this.$route.name == 'Reset') {
            this.$router.push('/' + this.$route.params.page)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.home {
    > .content {
        @include display-flex();
        @include align-items(flex-start);
        @include justify-content(center);
        background-color: transparent;
        height: 100% !important;
        padding-bottom: 0 !important;
    }
}
</style>
